import React, { useState } from "react";
import axios from "axios";

const SignUp = () => {
  const [addEmail, setAddEmail] = useState({
    name: "",
    email: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://restaurant-mark-graf-server.onrender.com/add-email",
        addEmail
      )
      .then(() => {
        setAddEmail({ name: "", email: "" });
      })
      .catch((err) => {
        console.log(err.message);
      });
    setAddEmail({ name: "", email: "" });
  };
  return (
    <>
      <h1 className="third-section_headline text-3xl font-semibold mb-10 md:me-auto md:ms-5">
        Nichts verpassen!
      </h1>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-10 justify-center items-center mb-10"
      >
        <div className="space-y-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Einfach anmelden und unser Wochenmenü per Mail erhalten
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-orange2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange1 sm:max-w-md">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent ps-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="John Doe"
                    onChange={(e) =>
                      setAddEmail({ ...addEmail, name: e.target.value })
                    }
                    value={addEmail.name}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-orange2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange1 sm:max-w-md">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="block flex-1 border-0 bg-transparent ps-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="john.doe@example.com"
                    onChange={(e) =>
                      setAddEmail({ ...addEmail, email: e.target.value })
                    }
                    value={addEmail.email}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <input
          type="submit"
          value="Senden"
          className="btn ms-auto me-auto border-2 border-orange1 rounded-full px-4 py-2 text-white font-semibold cursor-pointer hover:bg-orange1 hover:text-black1"
        />
      </form>
    </>
  );
};

export default SignUp;

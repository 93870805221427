import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);
    axios
      .post("https://restaurant-mark-graf-server.onrender.com/login", data)
      .then((res) => {
        console.log(res);
        localStorage.setItem("loggedIn", res.data.message);
        navigate("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main className="w-screen h-screen flex flex-col items-center justify-center p-20">
      <div className="flex flex-col max-w-md p-6 rounded-md sm:p-10 dark:bg-gray-900 dark:text-gray-100">
        <div className="mb-8 text-center">
          <h1 className="my-3 text-4xl font-bold">Login</h1>
        </div>
        <form onSubmit={handleSubmit} className="space-y-12">
          <div className="space-y-4">
            <div>
              <label for="email" className="block mb-2 text-sm">
                Email address
              </label>
              <input
                onChange={(e) => setData({ ...data, email: e.target.value })}
                type="email"
                name="email"
                id="email"
                placeholder="leroy@jenkins.com"
                value={data.email}
                className="w-80 px-6 py-2 border rounded-md dark:border-gray-700 dark:bg-gray-900 dark:text-black "
              />
            </div>
            <div>
              <div className="flex justify-between mb-2">
                <label for="password" className="text-sm">
                  Password
                </label>
              </div>
              <input
                onChange={(e) => setData({ ...data, password: e.target.value })}
                type="password"
                name="password"
                id="password"
                placeholder="*****"
                className="w-full px-3 py-2 border rounded-md dark:border-gray-700 dark:bg-gray-900 dark:text-black "
                autoComplete="on"
                value={data.password}
              />
            </div>
          </div>
          <div className="space-y-2">
            <div>
              <button
                type="submit"
                className="w-full px-8 py-3 font-semibold rounded-md dark:bg-violet-500 hover:dark:bg-violet-700 dark:text-gray-900"
              >
                Senden
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default Login;
// "email": "eberhardts.mark.graf@gmail.com",
// "password": "eberhardt.admin.restaurant"
// gmailAppPasswort: ztzm givo vcci ksfq

// php myadmin

// Mysql datenbankname: u847271205_Restaurant
// Mysql benutzername: u847271205_eberhardts
// Mysql passwort: eberhardt.admin.restaurant1R

// API url: https://restaurant-mark-graf-server.onrender.com

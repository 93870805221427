import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import navIcon from "../pictures/icons8-menü-50.png";
import closeIcon from "../pictures/icons8-löschen-50.png";
import horLine from "../pictures/icons8-horizontal-line-30.png";

const Navigation = () => {
  const navRef = useRef();
  const [navHeight, setNavHeight] = useState(0);
  useEffect(() => {
    setNavHeight(navRef.current.offsetHeight);
  }, []);

  const menuStyle = {
    top: `${navHeight}px`,
  };

  const handleClickToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 950);
  const [menuOpen, setMenuOpen] = useState(false); // New state for tracking if the menu is open

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu open state
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 950);
    });
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 400);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 400);
    };

    window.addEventListener("resize", checkScreenSize);

    // Cleanup function
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const title = isSmallScreen ? (
    <>
      Eberhardt's <br /> Mark Graf
    </>
  ) : (
    "Eberhardt's Mark Graf"
  );
  return (
    <>
      <nav
        ref={navRef}
        className="nav-container w-screen z-50 fixed top-0 transition-all duration-300 ease-in-out p-4 flex justify-between items-center"
      >
        <h2
          onClick={handleClickToTop}
          className="text-3xl font-medium cursor-pointer"
        >
          {title}
        </h2>

        {isMobile ? (
          <img
            className="z-10 transition-all duration-300 ease-in-out"
            src={menuOpen ? closeIcon : navIcon}
            alt="navbar-icon"
            onClick={toggleMenu}
          />
        ) : (
          <ul className="flex gap-4 font-medium">
            <NavLink
              className="navlink"
              onClick={() => {
                const menu = document.querySelector(".fourth-section");
                menu.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Menü
            </NavLink>
            <span>|</span>
            <NavLink
              className="navlink"
              onClick={() => {
                const famousFood = document.querySelector(".third-section");
                famousFood.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Unsere Hits
            </NavLink>
            <span>|</span>
            <NavLink
              className="navlink"
              onClick={() => {
                const weakMenu = document.querySelector(".fifth-section");
                weakMenu.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Wochen Menü
            </NavLink>
            <span>|</span>
            <NavLink
              className="navlink"
              onClick={() => {
                const gallery = document.querySelector(".Eigth-section");
                gallery.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <NavLink className="navlink"></NavLink>
              Galerie
            </NavLink>
            <span>|</span>
            <NavLink
              className="navlink"
              onClick={() => {
                const about = document.querySelector(".second-section");
                about.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Über uns
            </NavLink>
            <span>|</span>
            <NavLink
              className="navlink"
              onClick={() => {
                const contact = document.querySelector(".Ninth-section");
                contact.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Kontakt
            </NavLink>
          </ul>
        )}
      </nav>
      {menuOpen && (
        <section
          className="menu-background fixed z-40 w-screen h-full transition-all duration-300 ease-in-out"
          style={menuStyle}
        >
          <ul className="flex flex-col justify-center items-center mt-10 ms-2 gap-4 font-bold text-lg">
            <NavLink
              className="navlink"
              onClick={() => {
                const menu = document.querySelector(".fourth-section");
                menu.scrollIntoView({ behavior: "smooth" });
                setMenuOpen(!menuOpen);
              }}
            >
              Menü
            </NavLink>
            <img className="w-16 h-4" src={horLine} alt="breackline" />
            <NavLink
              className="navlink"
              onClick={() => {
                const famousFood = document.querySelector(".third-section");
                famousFood.scrollIntoView({ behavior: "smooth" });
                setMenuOpen(!menuOpen);
              }}
            >
              Unsere Hits
            </NavLink>
            <img className="w-16 h-4" src={horLine} alt="breackline" />
            <NavLink
              className="navlink"
              onClick={() => {
                const weakMenu = document.querySelector(".fifth-section");
                weakMenu.scrollIntoView({ behavior: "smooth" });
                setMenuOpen(!menuOpen);
              }}
            >
              Wochen Menü
            </NavLink>
            <img className="w-16 h-4" src={horLine} alt="breackline" />
            <NavLink
              className="navlink"
              onClick={() => {
                const gallery = document.querySelector(".Eigth-section");
                gallery.scrollIntoView({ behavior: "smooth" });
                setMenuOpen(!menuOpen);
              }}
            >
              <NavLink className="navlink"></NavLink>
              Galerie
            </NavLink>
            <img className="w-16 h-4" src={horLine} alt="breackline" />
            <NavLink
              className="navlink"
              onClick={() => {
                const about = document.querySelector(".second-section");
                about.scrollIntoView({ behavior: "smooth" });
                setMenuOpen(!menuOpen);
              }}
            >
              Über uns
            </NavLink>
            <img className="w-16 h-4" src={horLine} alt="breackline" />
            <NavLink
              className="navlink"
              onClick={() => {
                const contact = document.querySelector(".Ninth-section");
                contact.scrollIntoView({ behavior: "smooth" });
                setMenuOpen(!menuOpen);
              }}
            >
              Kontakt
            </NavLink>
          </ul>
        </section>
      )}
    </>
  );
};

export default Navigation;

import React, { useState, useEffect } from "react";
import picture from "../pictures/restaurant1.jpg";

const AboutUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1500);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1500);
    });
  }, []);
  return (
    <div className="w-full">
      {isMobile ? (
        <div className="w-full flex flex-col items-center text-center">
          <div className="text-container flex flex-col gap-6 text-center">
            <h1 className="text-3xl font-semibold text-primary underline">
              Herzlich Willkommen <br /> im Wiener Gasthaus <br /> “Eberhardt's
              Mark Graf”!
            </h1>
            <p className="text-white w-2/3 opacity-80 mx-auto">
              Inmitten der charmanten Gassen Wiens erwartet Sie unser
              klassisches Restaurant mit einer herzlichen Atmosphäre und
              authentischer Wiener Küche. Unsere Küche ist ein wahres Fest für
              Genießer. Von knusprigen Wiener Schnitzeln bis hin zu duftenden
              Tafelspitz-Gerichten – wir servieren traditionelle Köstlichkeiten.
              Treten Sie ein und fühlen Sie sich wie zu Hause. Gerne richten wir
              für sie ihre nächste Veranstaltung aus, für Veranstaltungen bitten
              wir Platz für 50 bis 300 Personen und bieten sie eine Woche im
              vorraus zu Reservieren. <br /> <br />
              <span className="text-base font-medium underline text-orange1">
                Und das Beste:
              </span>{" "}
              Wir bieten eine kostenlose Hauszustellung bis 15 Uhr an, damit Sie
              unsere köstlichen Gerichte auch bequem zu Hause genießen können.
            </p>
            <span className="text-white w-2/3  opacity-85 mx-auto">
              Wir freuen uns darauf, Sie im “Eberharts Mark Graf” begrüßen zu
              dürfen!
            </span>
          </div>
          <img
            className="second-picture mt-10 my-auto mx-auto w-screen object-cover rounded-xl"
            src={picture}
            alt="restaurantpicture"
          />
        </div>
      ) : (
        <div className="w-full flex items-center justify-between">
          <div className="text-container ms-14 flex flex-col gap-6">
            <h1 className="text-3xl font-semibold text-primary underline">
              Herzlich Willkommen <br /> im Wiener Gasthaus <br /> “Eberhardt's
              Mark Graf”!
            </h1>
            <p className="text-white w-2/3 opacity-80">
              Inmitten der charmanten Gassen Wiens erwartet Sie unser
              klassisches Restaurant mit einer herzlichen Atmosphäre und
              authentischer Wiener Küche. Unsere Küche ist ein wahres Fest für
              Genießer. Von knusprigen Wiener Schnitzeln bis hin zu duftenden
              Tafelspitz-Gerichten – wir servieren traditionelle Köstlichkeiten.
              Treten Sie ein und fühlen Sie sich wie zu Hause. Gerne richten wir
              für sie ihre nächste Veranstaltung aus, für Veranstaltungen bieten
              wir Platz für 50 bis 300 Personen und bitten sie eine Woche im
              vorraus zu Reservieren. <br /> <br />
              <span className="text-base font-medium underline text-orange1">
                Und das Beste:
              </span>{" "}
              Wir bieten eine kostenlose Hauszustellung bis 15 Uhr an, damit Sie
              unsere köstlichen Gerichte auch bequem zu Hause genießen können.
            </p>
            <span className="text-white w-2/3  opacity-85">
              Wir freuen uns darauf, Sie im “Eberharts Mark Graf” begrüßen zu
              dürfen!
            </span>
          </div>
          <img
            className="second-picture h-full object-cover w-2/4 ms-20 me-14 rounded-xl"
            src={picture}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default AboutUs;

import React, { useState, useEffect } from "react";
import { BsPhone } from "react-icons/bs";
import { GoClock } from "react-icons/go";
import login from "../pictures/icons8-anmelden-abgerundet-rechts-100.png";
import facebook from "../pictures/icons8-facebook-neu-100.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1500);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1500);
    });
  }, []);

  return isMobile ? (
    <>
      <div className="w-screen flex flex-col justify-center items-center">
        <h1 className="text-3xl font-medium footer-text">
          Eberhardt's Mark Graf{" "}
          <span>
            <section className="top-container w-full flex text-base mt-5">
              <div className="top-container_top-bar flex items-center">
                <i className="flex items-center">
                  <BsPhone className="" />
                  0664/5173686
                </i>
                <i className="flex items-center ms-4">
                  <GoClock /> Mo-So: 06:00 - 00:00
                </i>
              </div>
            </section>
          </span>
        </h1>
        <span>©2024 All rights reserved</span>
        <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
          <a
            href="https://www.facebook.com/profile.php?id=100076844957767&locale=hi_IN&paipv=0&eav=AfaJwQt-yl78sUu0aBc4D8HdS7rS7hwl_GeeKCFNupbEcYPKupyoLhjdfrbBiZcxdgc"
            title="Facebook"
            className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-600 hover:bg-violet-900 dark:text-gray-900"
          >
            <img src={facebook} alt="facebook-icon" />
          </a>
          {localStorage.getItem("loggedIn") ? (
            <Link
              to="/admin"
              title="Login"
              className="flex items-center justify-center w-10 h-10 rounded-full bg-violet-600 hover:bg-violet-900 dark:text-gray-900"
            >
              <img src={login} alt="loginbutton" />
            </Link>
          ) : (
            <Link
              to="/login"
              title="Login"
              className="flex items-center justify-center w-10 h-10 rounded-full dark:bg-violet-400 hover:bg-violet-700 dark:text-gray-900"
            >
              <img src={login} alt="login-button" />
            </Link>
          )}
        </div>
      </div>
    </>
  ) : (
    <>
      <h1 className="text-3xl font-medium footer-text">
        Eberhardt's Mark Graf{" "}
        <span>
          <section className="top-container w-full flex text-base mt-5">
            <div className="top-container_top-bar flex items-center">
              <i className="flex items-center">
                <BsPhone className="" />
                0664/5173686
              </i>
              <i className="flex items-center ms-4">
                <GoClock /> Mo-So: 06:00 - 00:00
              </i>
            </div>
          </section>
        </span>
      </h1>
      <span>©2024 All rights reserved</span>
      <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
        <Link
          to="#"
          title="Facebook"
          className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-600 hover:bg-violet-900 dark:text-gray-900"
        >
          <img src={facebook} alt="facebook-icon" />
        </Link>
        {localStorage.getItem("loggedIn") ? (
          <Link
            to="/admin"
            title="Login"
            className="flex items-center justify-center w-10 h-10 rounded-full bg-violet-600 hover:bg-violet-900 dark:text-gray-900"
          >
            <img src={login} alt="loginbutton" />
          </Link>
        ) : (
          <Link
            to="/login"
            title="Login"
            className="flex items-center justify-center w-10 h-10 rounded-full dark:bg-violet-400 hover:bg-violet-700 dark:text-gray-900"
          >
            <img src={login} alt="login-button" />
          </Link>
        )}
      </div>
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import food1 from "../pictures/speise1.jpg";
import food2 from "../pictures/speise2.jpg";
import food3 from "../pictures/speise3.jpg";

const FavoriteFood = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1024);
    });
  }, []);
  return (
    <>
      {isMobile ? (
        <div className="flex flex-col items-center justify-center">
          <h1 className="third-section_headline sm:self-center lg:self-start justify-self-start mt-20 text-3xl font-semibold mb-10 me-auto ms-16">
            Beliebte Speisen
          </h1>

          <ul className="flex flex-col sm:gap-5 xl:gap-20 justify-center items-center mt-auto mb-auto">
            <div className="w-full rounded-sm flex flex-col gap-5 p-20 justify-between">
              <h1 className="text-2xl">Wiener Schnitzel</h1>
              <img
                className="w-full object-cover rounded-md" // Änderung hier
                src={food1}
                alt=""
              />
            </div>
            <div className="w-full h-full rounded-sm flex flex-col gap-5 p-20 justify-between">
              <h1 className="text-2xl justify-self-start">Zwiebelrostbraten</h1>
              <img
                className="w-full object-cover rounded-md"
                src={food2}
                alt=""
              />
            </div>
            <div className="w-full h-full rounded-sm flex flex-col gap-5 p-20 justify-between">
              <h1 className="text-2xl">Tafelspitz</h1>
              <img
                className="w-full object-cover rounded-md"
                src={food3}
                alt=""
              />
            </div>
          </ul>
        </div>
      ) : (
        <>
          <h1 className="third-section_headline self-start justify-self-start text-3xl font-semibold mb-10 me-auto ms-5">
            Beliebte Speisen
          </h1>

          <ul className="w-screen flex sm:flex-col lg:flex-row sm:gap-5 xl:gap-20 justify-center items-center mt-auto mb-auto">
            <li className="w-full rounded-sm flex flex-col gap-5 p-8 justify-between bg-black1">
              <h1 className="text-2xl">Wiener Schnitzel</h1>
              <img
                className="w-full object-cover h-72 rounded-md"
                src={food1}
                alt=""
              />
            </li>
            <li className="w-full rounded-sm flex flex-col gap-5 p-8 justify-between">
              <h1 className="text-2xl justify-self-start">Zwiebelrostbraten</h1>
              <img
                className="w-full object-cover h-72 rounded-md"
                src={food2}
                alt=""
              />
            </li>
            <li className="w-full rounded-sm flex flex-col gap-5 p-8 justify-between">
              <h1 className="text-2xl">Tafelspitz</h1>
              <img
                className="w-full object-cover h-72 rounded-md"
                src={food3}
                alt=""
              />
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default FavoriteFood;

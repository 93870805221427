import React, { useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";

const Admin = () => {
  const [file, setFile] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "https://restaurant-mark-graf-server.onrender.com/upload";
    // create formdata
    const formData = new FormData();
    formData.append("file", file);

    // send data to database

    axios
      .post(url, formData)
      .then((res) => {
        navigate("/");
        // redirect to homepage
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const time = new Date();
  // const munt = time.getMinutes();

  // useEffect(() => {
  //   const currentTime = new Date();
  //   const hour = currentTime.getHours();
  //   const minute = currentTime.getMinutes();

  //   if (hour === 16 && minute === 14) {
  //     alert("Alert at 4:10pm!");
  //   }
  // }, [munt]);

  return (
    <main className="w-screen h-screen bg-white">
      <nav className="nav-container w-screen p-4 flex justify-between items-center">
        <h1 className="text-xl font-medium">Admin Dashboard</h1>
        <div>
          <NavLink
            to="/"
            className="newsletter-btn text-white py-2 px-4 rounded-full hover:bg-orange1 hover:text-black1"
          >
            Homepage
          </NavLink>
        </div>
      </nav>
      {/* weekly menu section */}
      <section className="weekly-menu">
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-1/4 border-2 flex flex-col gap-1 justify-center items-center p-14 mx-auto mt-40 rounded-lg bg-white shadow-xl"
        >
          <div className="flex flex-col gap-2">
            <label className="text-black text-xl" htmlFor="file">
              Wochenmenü
            </label>
            <input
              className="text-black border-2 w-full p-1 rounded-md cursor-pointer"
              type="file"
              name="file"
              id="file"
              placeholder=""
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <input
            type="submit"
            value="Hochladen"
            className="p-2 mt-3 text-black border-2 rounded-md cursor-pointer"
          />
        </form>
      </section>
    </main>
  );
};

export default Admin;

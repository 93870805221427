import React, { useState, useEffect } from "react";

const Menu = () => {
  const [activeSection, setActiveSection] = useState("Frühstück");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1200);
    });
  }, []);

  const menu = {
    Frühstück: [
      {
        name: "Mini Frühstück mit Hauskaffee und Buttersemmel",
        price: "3,90",
      },
      { name: "Kleines Früstück", price: "4,90" },
      { name: "Wiener Frühstück", price: "6,90" },
      { name: "Großes Frühstück", price: "8,90" },
      { name: "Ham and Eggs mit 3 Eiern", price: "7,90" },
      {
        name: "Rühr-, oder Spiegelei bzw Eierspeis mit 3 Eiern",
        price: "7,90",
      },
      {
        name: "Schinkenomelette mit Schinken, Käse oder Gemüse",
        price: "7,90",
      },
      { name: "Schinken oder Käse Toast", price: "4,90" },
      { name: "Cornflakes mit Milch und Honig", price: "3,90" },
    ],
    Suppen: [
      {
        name: `Klare Suppe mit Frittaten, Nudeln oder Backerbsen`,
        price: "3,90",
      },
      { name: "Leberknödelsuppe", price: "3,90" },
      { name: "Grießnockerlsuppe", price: "3,90" },
      { name: "Alt Wiener Suppentopf", price: "7,90" },
      { name: "Gulaschsuppe", price: "6.90" },
      { name: "Knoblauchcremesuppe mit Croutons", price: "6.90" },
    ],
    "Kleine Speisen": [
      { name: "Würstel mit Senf, Kren", price: "6.90" },
      { name: "Würstel mit Saft", price: "7.90" },
      { name: "Schinken, Käse Toast", price: "4,90" },
    ],
    Hauptspeisen: [
      { name: "Schweinsbraten", price: "14,90" },
      { name: "Schnitzel von der Pute oder Schwein", price: "13,90" },
      { name: "Cordon Bleu von der Pute oder Schwein", price: "15,90" },
      { name: "Großes Rindsgulasch", price: "13,90" },
      { name: "Wiener Tafelspitz", price: "19,90" },
      { name: "Zwiebelrostbraten", price: "19,90" },
      { name: "Zanderfilet vom Grill", price: "19,90" },
      { name: "Lachssteak mit Reis in pikanter Sauce", price: "21,90" },
      { name: "Zigeunerschnitzel mit Bratkartoffeln", price: "15,90" },
      { name: "Cevapcici mit Pommes und Zwiebelsenf", price: "12,90" },
      { name: "Berner Würstel mit Zwiebelsenf und Pommes", price: "12,90" },
    ],
    Vegetarisch: [
      { name: "Eiernockerl", price: "12,90" },
      { name: "Käsespätzle mit Röstzwiebeln und Blattsalat", price: "12,90" },
      { name: "Gebackenes Gemüse mit Sauce Tartare", price: "12,90" },
      { name: "Knödel mit Ei und Blattsalat", price: "12,90" },
    ],
    Beilagen: [
      { name: "Petersilkartoffeln", price: "3,90" },
      { name: "Bratkartoffeln", price: "3,90" },
      { name: "Pommes", price: "3,90" },
      { name: "Reis", price: "3,90" },
      { name: "Semmelknödel", price: "3,90" },
      { name: "Gemüse nach Saison", price: "3,90" },
      { name: "Ketchup", price: "1,90" },
      { name: "Mayonaise", price: "1,90" },
      { name: "Gebäck", price: "1,90" },
    ],
    Salate: [
      { name: "Steirer Salat", price: "12,80" },
      { name: "Thunfisch Salat", price: "12.80" },
      { name: "Griechischer Bauernsalat", price: "12.80" },
      { name: "Saure Wurst in Essig und Öl", price: "9,80" },
      {
        name: "Beilagen Salate: Kartoffel, Gurke, Blatt, Kraut",
        price: "4,10",
      },
    ],
    Desserts: [
      { name: "Apfel oder Topfenstrudel", price: "6,90" },
      { name: "Schokokuchen mit Schlag", price: "6,90" },
      { name: "Marillenpalatschinke", price: "6,90" },
      { name: "Schokopalatschinke", price: "4,90" },
      { name: "Eispalatschinke", price: "4,90" },
      { name: "Hausgemachte Wiener Mehlspeisen", price: "4,90" },
    ],
    Eiskarte: [
      { name: "Gemischtes Eis mit Schlag und Früchten", price: "7,80" },
      { name: "Eiskaffee", price: "5,80" },
      { name: "Gemischtes Eis mit Schlag", price: "7,90" },
      { name: "Eispalatschinke 2 Stück", price: "7,90" },
      { name: "Schokokuchen mit Schlag und Eis", price: "7,90" },
      { name: "Pfirsich Melba", price: "9,80" },
      { name: "Eiswafferl und Mannerschnitte", price: "1,90" },
      { name: "Eisbecher", price: "8,90" },
    ],
  };
  return (
    <>
      {isMobile ? (
        <>
          <h1 className="third-section_headline sm:self-center lg:self-start justify-self-start text-3xl font-semibold mb-10 me-auto ms-24">
            Unsere Karte
          </h1>
          <div className="w-full h-full">
            <div className="w-full flex justify-center items-center">
              <div className="grid grid-cols-1 items-center justify-items-center mx-auto mt-4 mb-20">
                <div className="text-center">
                  {Object.keys(menu).map((section) => (
                    <button
                      key={section}
                      className={`px-2 py-1 rounded-full ${
                        activeSection === section
                          ? "text-orange1 underline"
                          : "bg-gray-200"
                      }`}
                      onClick={() => setActiveSection(section)}
                    >
                      {section}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-32 h-full">
              {/* <h2 className="text-lg font-bold mb-2">{activeSection}</h2> */}

              <div className="flex flex-col gap-3 w-full">
                {menu[activeSection].map((item) => (
                  <div
                    key={item.name}
                    className="text-gray-600 text-base flex flex-col"
                  >
                    <div className="flex gap-3 justify-between">
                      <span className="text-lg">{item.name} </span>
                      <span>{item.price}€</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h1 className="third-section_headline self-start justify-self-start text-3xl font-semibold mb-10 me-auto ms-24">
            Unsere Karte
          </h1>
          <div className="w-full h-full">
            <div className="flex justify-center mx-auto mt-4 mb-20">
              {Object.keys(menu).map((section) => (
                <button
                  key={section}
                  className={`px-2 py-1 rounded-full ${
                    activeSection === section
                      ? "text-orange1 underline"
                      : "bg-gray-200"
                  }`}
                  onClick={() => setActiveSection(section)}
                >
                  {section}
                </button>
              ))}
            </div>
            <div className="flex flex-col justify-center items-center gap-32 h-full">
              {/* <h2 className="text-lg font-bold mb-2">{activeSection}</h2> */}

              <div className="flex flex-col gap-3 w-full px-96">
                {menu[activeSection].map((item) => (
                  <div
                    key={item.name}
                    className="text-gray-600 text-base flex flex-col"
                  >
                    <div className="flex gap-3 justify-between">
                      <span className="text-lg">{item.name} </span>
                      <span>{item.price}€</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Menu;

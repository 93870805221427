import React from "react";
import galerie1 from "../pictures/galerie1.jpg";
import galerie2 from "../pictures/galerie2.jpg";
import galerie3 from "../pictures/galerie3.jpg";
import galerie4 from "../pictures/galerie4.jpg";
import galerie5 from "../pictures/galerie5.jpg";
import galerie6 from "../pictures/galerie6.jpg";

const Gallery = () => {
  const data = [
    {
      imageLink: galerie1,
    },
    {
      imageLink: galerie2,
    },
    {
      imageLink: galerie3,
    },
    {
      imageLink: galerie4,
    },
    {
      imageLink: galerie5,
    },
    {
      imageLink: galerie6,
    },
  ];
  return (
    <>
      <h1 className="third-section_headline sm:self-center lg:self-start justify-self-start text-3xl font-semibold mb-10 me-auto ms-5">
        Galerie
      </h1>
      <div className="w-screen grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-3">
        {data.map(({ imageLink }, index) => (
          <div key={index}>
            <img
              className="h-full w-full rounded-lg object-cover object-center"
              src={imageLink}
              alt="galleryphoto"
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Gallery;

import React, { useState, useEffect } from "react";
import PDF from "../components/PDF";
import SignUp from "../components/SignUp";
import Navigation from "../components/Navigation";
import AboutUs from "../components/AboutUs";
import FavoriteFood from "../components/FavoriteFood";
import Gallery from "../components/Gallery";
import Menu from "../components/Menu";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 700);
    });
  }, []);
  return (
    <main>
      <header className="top-header w-screen h-screen p-3 flex flex-col items-center">
        <Navigation />
      </header>
      <section className="second-section w-screen py-20">
        <AboutUs />
      </section>
      <section className="third-section cursor-default w-screen p-24 flex flex-col items-center bg-gray">
        <FavoriteFood />
      </section>
      <section className="fourth-section cursor-default w-screen py-24 flex flex-col items-center bg-black1">
        <Menu />
      </section>
      <section className="fifth-section cursor-default w-screen p-24 flex flex-col items-center bg-gray">
        <div className="w-screen h-full flex flex-col justify-center items-center gap-32">
          {isMobile ? (
            <PDF />
          ) : (
            <>
              <h1 className="third-section_headline self-start justify-self-start text-3xl font-semibold mb-10 me-auto ms-5">
                Wochen Menü und Events
              </h1>
              <PDF />
            </>
          )}
        </div>
      </section>
      <section className="Sixth-section cursor-default w-screen mb-10 me-auto mt-10 flex flex-col items-center bg-black1">
        <SignUp />
      </section>
      <section className="Eigth-section cursor-default p-24 flex flex-col items-center bg-black1">
        <Gallery />
      </section>
      <section className="Ninth-section cursor-default w-screen p-24 gap-4 flex flex-col items-center bg-gray">
        <Contact />
      </section>
      <footer className="flex p-8 justify-between items-center">
        <Footer />
      </footer>
    </main>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Stile importieren

const PDF = () => {
  const [pdfFileName, setPdfFileName] = useState("");

  useEffect(() => {
    axios
      .get("https://restaurant-mark-graf-server.onrender.com/weekly-menu")
      .then((res) => {
        setPdfFileName(res.data);
      });
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 700);
    });
  }, []);
  const url =
    "https://restaurant-mark-graf-server.onrender.com/public/uploads/";
  return (
    <>
      {isMobile ? (
        <div className="w-full flex flex-col items-end justify-center">
          <h1 className="third-section_headline text-3xl font-semibold mb-10">
            Wochen Menü und Events
          </h1>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "100vh",
                width: "100%",
              }}
              theme={{
                theme: "dark",
              }}
            >
              <Viewer
                defaultScale={SpecialZoomLevel.PageWidth}
                LayoutMode="Double"
                fileUrl={`${url}${pdfFileName[0]?.name}`} // Passe den Pfad zu deiner PDF-Datei an
              />
            </div>
          </Worker>
        </div>
      ) : (
        <>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "100vh",
                width: "70%",
              }}
              theme={{
                theme: "dark",
              }}
            >
              <Viewer
                defaultScale={SpecialZoomLevel.PageWidth}
                LayoutMode="Double"
                fileUrl={`${url}${pdfFileName[0]?.name}`} // Passe den Pfad zu deiner PDF-Datei an
              />
            </div>
          </Worker>
        </>
      )}
    </>
  );
};

export default PDF;

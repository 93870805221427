import React, { useState, useEffect } from "react";
import axios from "axios";
import { SlLocationPin } from "react-icons/sl";
import { FaRegClock } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { BsPhone } from "react-icons/bs";

const Contact = () => {
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 700);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://restaurant-mark-graf-server.onrender.com/user-mail",
        emailData
      )
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
    setEmailData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <>
      {isMobile ? (
        <>
          <h1 className="third-section_headline self-start justify-self-start text-3xl font-semibold mb-10 me-auto ms-5">
            Kontakt
          </h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2659.150023885946!2d16.3272483757562!3d48.2037261712508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d07faf81253b7%3A0xe6e3f7df2f47f2ba!2sMarkgraf-R%C3%BCdiger-Stra%C3%9Fe%2023%2C%201150%20Wien!5e0!3m2!1sde!2sat!4v1709398619371!5m2!1sde!2sat"
            width="140%"
            height="400"
            style={{ border: 0, borderRadius: "5px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Google Maps"
          ></iframe>
          <div className="w-screen flex flex-col justify-center items-center">
            <aside className="h-full">
              <ul className="flex flex-col gap-10 justify-center">
                <div>
                  <li className="text-xl flex gap-2 items-center">
                    <SlLocationPin />
                    Standort:
                  </li>
                  <li className="opacity-65">
                    Markgraf-Rüdiger-Straße 23, 1150 Wien
                  </li>
                </div>
                <div>
                  <li className="text-xl flex gap-2 items-center">
                    <FaRegClock />
                    Öffnungszeiten:
                  </li>
                  <li className="opacity-65">
                    Mo-So 06:00 - 00:00 Uhr <br />
                    Küche 06:00 - 22:00 Uhr
                  </li>
                </div>
                <div>
                  <li className="text-xl flex gap-2 items-center">
                    <TfiEmail />
                    Email:
                  </li>
                  <li className="opacity-65">eberhardts.mark.graf@gmail.com</li>
                </div>
                <div>
                  <li className="text-xl flex gap-2 items-center">
                    <BsPhone />
                    Telefon:
                  </li>
                  <li className="opacity-65">0664/5173686</li>
                </div>
              </ul>
            </aside>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-5 justify-center items-center"
            >
              <div className="space-y-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-orange2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange1 sm:max-w-md">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="name"
                          className="block flex-1 border-0 bg-transparent ps-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="John Doe"
                          onChange={(e) =>
                            setEmailData({ ...emailData, name: e.target.value })
                          }
                          value={emailData.name}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-orange2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange1 sm:max-w-md">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          autoComplete="email"
                          className="block flex-1 border-0 bg-transparent ps-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="john.doe@example.com"
                          onChange={(e) =>
                            setEmailData({
                              ...emailData,
                              email: e.target.value,
                            })
                          }
                          value={emailData.email}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full ">
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Nachricht
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="message"
                        name="message"
                        rows={3}
                        className="bg-transparent p-2 w-full flex rounded-md shadow-sm ring-1 ring-inset ring-orange2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange1 sm:max-w-md"
                        defaultValue={""}
                        onChange={(e) =>
                          setEmailData({
                            ...emailData,
                            message: e.target.value,
                          })
                        }
                        value={emailData.message}
                      />
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600 opacity-65">
                      Wir freuen uns über jedes Feedback um uns laufend zu
                      verbessern.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="submit"
                value="Senden"
                className="btn ms-auto me-auto border-2 border-orange1 rounded-full px-4 py-2 text-white font-semibold cursor-pointer hover:bg-orange1 hover:text-black1"
              />
            </form>
          </div>
        </>
      ) : (
        <>
          <h1 className="third-section_headline self-start justify-self-start text-3xl font-semibold mb-10 me-auto ms-5">
            Kontakt
          </h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2659.150023885946!2d16.3272483757562!3d48.2037261712508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d07faf81253b7%3A0xe6e3f7df2f47f2ba!2sMarkgraf-R%C3%BCdiger-Stra%C3%9Fe%2023%2C%201150%20Wien!5e0!3m2!1sde!2sat!4v1709398619371!5m2!1sde!2sat"
            width="100%"
            height="400"
            style={{ border: 0, borderRadius: "5px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Google Maps"
          ></iframe>
          <div className="w-screen flex justify-around items-center">
            <aside className="h-full">
              <ul className="flex flex-col gap-10 justify-center">
                <div>
                  <li className="text-xl flex gap-2 items-center">
                    <SlLocationPin />
                    Standort:
                  </li>
                  <li className="opacity-65">
                    Markgraf-Rüdiger-Straße 23, 1150 Wien
                  </li>
                </div>
                <div>
                  <li className="text-xl flex gap-2 items-center">
                    <FaRegClock />
                    Öffnungszeiten:
                  </li>
                  <li className="opacity-65">
                    Mo-So 06:00 - 00:00 Uhr <br />
                    Küche 06:00 - 22:00 Uhr
                  </li>
                </div>
                <div>
                  <li className="text-xl flex gap-2 items-center">
                    <TfiEmail />
                    Email:
                  </li>
                  <li className="opacity-65">eberhardts.mark.graf@gmail.com</li>
                </div>
                <div>
                  <li className="text-xl flex gap-2 items-center">
                    <BsPhone />
                    Telefon:
                  </li>
                  <li className="opacity-65">0664/5173686</li>
                </div>
              </ul>
            </aside>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-5 justify-center items-center"
            >
              <div className="space-y-12">
                {/* <h2 className="text-base font-semibold leading-7 text-gray-900">
                Schreibe uns eine Nachricht
              </h2> */}
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-orange2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange1 sm:max-w-md">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="name"
                          className="block flex-1 border-0 bg-transparent ps-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="John Doe"
                          onChange={(e) =>
                            setEmailData({ ...emailData, name: e.target.value })
                          }
                          value={emailData.name}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-orange2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange1 sm:max-w-md">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          autoComplete="email"
                          className="block flex-1 border-0 bg-transparent ps-2 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="john.doe@example.com"
                          onChange={(e) =>
                            setEmailData({
                              ...emailData,
                              email: e.target.value,
                            })
                          }
                          value={emailData.email}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full ">
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Nachricht
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="message"
                        name="message"
                        rows={3}
                        className="bg-transparent p-2 w-full flex rounded-md shadow-sm ring-1 ring-inset ring-orange2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange1 sm:max-w-md"
                        defaultValue={""}
                        onChange={(e) =>
                          setEmailData({
                            ...emailData,
                            message: e.target.value,
                          })
                        }
                        value={emailData.message}
                      />
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600 opacity-65">
                      Wir freuen uns über jedes Feedback um uns laufend zu
                      verbessern.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="submit"
                value="Senden"
                className="btn ms-auto me-auto border-2 border-orange1 rounded-full px-4 py-2 text-white font-semibold cursor-pointer hover:bg-orange1 hover:text-black1"
              />
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Contact;
